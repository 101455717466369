// libraries
import React from 'react';
import { t } from 'src/modules/i18n';
// assets
import LogoIcon from 'src/assets/icons/logo.svg';
// styles
import './index.scss';

const Logo = () => (
  <div className="logo">
    <LogoIcon className="logo_icon" />
    <div>
      <h1 className="logo_title">{t('logo.title')}</h1>
      <h2 className="logo_subtitle">{t('logo.sub_title')}</h2>
    </div>
  </div>
);

export default Logo;
