// types
import { SET } from './types';

export const initialState = {
  pageClickMode: false,
  btnTitle: '',
  pageVariant: 'thinking',
  autoSkip: 0,
  pageTitle: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload.cfg,
      };

    default:
      return state;
  }
};
