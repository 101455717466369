// libraries
import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
// constants
import { PAGES_VARIANTS } from 'src/constants/general';
// styles
import './index.scss';
// lazy pages
const FirstPage = lazy(() => import('./pages/first-page'));
const SecondPage = lazy(() => import('./pages/second-page'));
const ThirdPage = lazy(() => import('./pages/third-page'));

const Content = () => {
  const { pageVariant } = useSelector((state) => state.remoteConfig);
  const contents = {
    [PAGES_VARIANTS.thinking]: <FirstPage />,
    [PAGES_VARIANTS.happiness]: <SecondPage />,
    [PAGES_VARIANTS.motivation]: <ThirdPage />,
  };
  const firstPage = contents[PAGES_VARIANTS.thinking];
  const currentContents = contents[pageVariant];

  return (
    <main className="main-content">
      <Suspense fallback={<></>}>{currentContents || firstPage}</Suspense>
    </main>
  );
};

export default Content;
