// libraries
import React from 'react';
// components
import Logo from 'src/components/header/logo';
import Title from 'src/components/header/title';
// styles
import './index.scss';

const Header = () => (
  <header className="header">
    <Logo />
    <Title />
  </header>
);

export default Header;
