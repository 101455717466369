// libraries
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// hooks
import useHeight from 'src/hooks/use-height';
// styles
import './index.scss';

const Wrapper = ({ children, height, onClick, bgClass }) => {
  const containerHeight = useHeight();

  return (
    <div
      onClick={onClick}
      className={classNames('wrapper', bgClass)}
      style={{ height: height || containerHeight }}
    >
      {children}
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  bgClass: PropTypes.string.isRequired,
};

export default Wrapper;
