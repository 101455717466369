// libraries
import React from 'react';
import { t } from 'src/modules/i18n';
import { useSelector } from 'react-redux';
// constants
import { PAGES_VARIANTS } from 'src/constants/general';
// styles
import './index.scss';

const Title = () => {
  const { pageVariant, pageTitle } = useSelector((state) => state.remoteConfig);
  const isThirdPageVariant = pageVariant === PAGES_VARIANTS.motivation;
  const isCurrentPage = PAGES_VARIANTS[pageVariant];
  const localTitle = t(`${isCurrentPage ? pageVariant : PAGES_VARIANTS.thinking}_page.main_title`);
  const title = pageTitle || localTitle;

  return (
    <>
      <h2 className="main-title">{title}</h2>
      {isThirdPageVariant && (
        <h3 className="main-subtitle">{t(`${pageVariant}_page.main_sub_title`)}</h3>
      )}
    </>
  );
};

export default Title;
