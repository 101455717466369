// libraries
import React from 'react';
// assets
import LoaderIcon from 'src/assets/icons/loader.svg';
// styles
import './index.scss';

const Loader = () => (
  <div className="loader">
    <LoaderIcon className="loader_content" />
  </div>
);

export default Loader;
