// libraries
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'src/modules/i18n';
// actions
import { downloadApp } from 'src/store/app/actions';
// hoc
import Wrapper from 'src/hoc/wrapper';
// constants
import { PAGES_VARIANTS } from 'src/constants/general';
// modules
import Analytics from 'src/modules/analytics';
// components
import Loader from 'src/components/loader';
import Header from 'src/components/header';
import Button from 'src/components/button';
import Content from './main';

const App = () => {
  const initialHeight = 550;
  const [containerHeight, setContainerHeight] = useState(initialHeight);
  const {
    remoteConfig: { btnTitle, pageClickMode, pageVariant, autoSkip },
    app: { loaded },
  } = useSelector((state) => state);
  const firstPageVariant = PAGES_VARIANTS.thinking;
  const pageBackground = PAGES_VARIANTS[pageVariant]
    ? `wrapper__${pageVariant}-page-bg`
    : `wrapper__${firstPageVariant}-page-bg`;

  useEffect(() => {
    let autoSkipTimeout = null;

    if (autoSkip) {
      autoSkipTimeout = setTimeout(() => {
        Analytics.trackEvent('redirect', 'action');
        downloadApp();
      }, autoSkip * 1000);
    }

    return () => {
      clearTimeout(autoSkipTimeout);
    };
  }, [autoSkip]);

  useEffect(() => {
    const heightTimeout = setTimeout(() => {
      setContainerHeight(window.innerHeight);
      window.scrollTo(0, 0);
    }, 50);

    return () => {
      clearTimeout(heightTimeout);
    };
  });

  const handleBtnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Analytics.trackEvent('install_now', 'click');
    downloadApp();
  };

  const handlePageClick = () => {
    if (pageClickMode) {
      Analytics.trackEvent('page', 'click');
      downloadApp();
    }
  };

  return (
    <Wrapper onClick={handlePageClick} height={containerHeight} bgClass={pageBackground}>
      {!loaded ? (
        <Loader />
      ) : (
        <>
          <Header />
          <Content />
          <Button
            title={btnTitle || t('button.title')}
            onClick={handleBtnClick}
            buttonClass={pageVariant}
          />
        </>
      )}
    </Wrapper>
  );
};

export default App;
