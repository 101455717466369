// libraries
import React from 'react';
import PropTypes from 'prop-types';
// styles
import './index.scss';

const Button = ({ title, onClick, buttonClass }) => (
  <button onClick={onClick} type="button" className={`button animate-grow ${buttonClass}`}>
    {title}
  </button>
);

Button.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonClass: PropTypes.string,
};

Button.defaultProps = {
  buttonClass: '',
};

export default Button;
